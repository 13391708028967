/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const destination: Record<string, TDestination> = {
  'en-AU': {
    destination: {
      title: 'Explore the world with us',
      description: 'Learn more about the spectacular destinations and attractions that you can experience on an expedition cruise with us',
      destinationsButton: 'Go to destination',
      storiesTitle: 'Stories from',
      associatedVoyagesTitle: 'Cruises to',
      highlights: {
        reasonsTo: 'Reasons to'
      },
      seasonality: {
        title: 'When to see',
        bestTime: 'Best time',
        goodTime: 'Good time',
        noServiceTo: 'No service to',
        from: 'from',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Ships to',
      shipsToShortDescription: 'Explore our ships that regularly sail to',
      topExperiencesTitle: 'Top experiences',
      seeAllFaqs: 'See all FAQs',
      secondaryTitle: 'Our destinations',
      activities: {
        footnote: '* Please note that these are optional activities; an additional cost may apply.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Read our travel guide and FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'View cruises'
        }
      }
    }
  },
  'en-GB': {
    destination: {
      title: 'Explore the world with us',
      description: 'Learn more about the spectacular destinations and attractions that you can experience on an expedition cruise with us',
      destinationsButton: 'Go to destination',
      storiesTitle: 'Stories from',
      associatedVoyagesTitle: 'Cruises to',
      highlights: {
        reasonsTo: 'Reasons to'
      },
      seasonality: {
        title: 'When to see',
        bestTime: 'Best time',
        goodTime: 'Good time',
        noServiceTo: 'No service to',
        from: 'from',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Ships to',
      shipsToShortDescription: 'Explore our ships that regularly sail to',
      topExperiencesTitle: 'Top experiences',
      seeAllFaqs: 'See all FAQs',
      secondaryTitle: 'Our destinations',
      activities: {
        footnote: '* Please note that these are optional activities; an additional cost may apply.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Read our travel guide and FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'View cruises'
        }
      }
    }
  },
  'en-US': {
    destination: {
      title: 'Explore the world with us',
      description: 'Learn more about the spectacular destinations and attractions that you can experience on an expedition cruise with us',
      destinationsButton: 'Go to destination',
      storiesTitle: 'Stories from',
      associatedVoyagesTitle: 'Cruises to',
      highlights: {
        reasonsTo: 'Reasons to'
      },
      seasonality: {
        title: 'When to see',
        bestTime: 'Best time',
        goodTime: 'Good time',
        noServiceTo: 'No service to',
        from: 'from',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Ships to',
      shipsToShortDescription: 'Explore our ships that regularly sail to',
      topExperiencesTitle: 'Top experiences',
      seeAllFaqs: 'See all FAQs',
      secondaryTitle: 'Our destinations',
      activities: {
        footnote: '* Please note that these are optional activities; an additional cost may apply.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Read our travel guide and FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'View cruises'
        }
      }
    }
  },
  'de-DE': {
    destination: {
      title: 'Entdecken Sie die Welt mit uns',
      description: 'Erfahren Sie mehr über die spektakulären Reiseziele und besonderen Erlebnisse, die eine Expeditions-Seereise mit uns unvergesslich machen',
      destinationsButton: 'Weiter zum Reiseziel',
      storiesTitle: 'Geschichten von',
      associatedVoyagesTitle: 'Seereisen nach',
      highlights: {
        reasonsTo: 'Gründe für'
      },
      seasonality: {
        title: 'Die beste Reisezeit',
        bestTime: 'Beste Reisezeit',
        goodTime: 'Gute Reisezeit',
        noServiceTo: 'Kein Service für',
        from: 'von',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Schiffe nach',
      shipsToShortDescription: 'Entdecken Sie unsere Schiffe im regulären Betrieb nach',
      topExperiencesTitle: 'Erstklassige Erlebnisse',
      seeAllFaqs: 'Alle FAQs ansehen',
      secondaryTitle: 'Unsere Reiseziele',
      activities: {
        footnote: '* Bitte beachten Sie, dass es sich hierbei um einen optionalen Ausflug handelt und zusätzliche Kosten anfallen.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Lesen Sie unseren Reiseführer und unsere FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Seereisen ansehen'
        }
      }
    }
  },
  'gsw-CH': {
    destination: {
      title: 'Entdecken Sie die Welt mit uns',
      description: 'Erfahren Sie mehr über die spektakulären Reiseziele und besonderen Erlebnisse, die eine Expeditions-Seereise mit uns unvergesslich machen',
      destinationsButton: 'Weiter zum Reiseziel',
      storiesTitle: 'Geschichten von',
      associatedVoyagesTitle: 'Seereisen nach',
      highlights: {
        reasonsTo: 'Gründe für'
      },
      seasonality: {
        title: 'Die beste Reisezeit',
        bestTime: 'Beste Reisezeit',
        goodTime: 'Gute Reisezeit',
        noServiceTo: 'Kein Service für',
        from: 'von',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Schiffe nach',
      shipsToShortDescription: 'Entdecken Sie unsere Schiffe im regulären Betrieb nach',
      topExperiencesTitle: 'Erstklassige Erlebnisse',
      seeAllFaqs: 'Alle FAQs ansehen',
      secondaryTitle: 'Unsere Reiseziele',
      activities: {
        footnote: '* Bitte beachten Sie, dass es sich hierbei um einen optionalen Ausflug handelt und zusätzliche Kosten anfallen.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Lesen Sie unseren Reiseführer und unsere FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Seereisen ansehen'
        }
      }
    }
  },
  'fr-FR': {
    destination: {
      title: 'Explorez le monde avec nous',
      description: 'Apprenez-en davantage sur les destinations et les sites spectaculaires que vous pouvez explorer lors de l’une de nos croisières d’expédition',
      destinationsButton: 'Explorer la destination',
      storiesTitle: 'Articles sur',
      associatedVoyagesTitle: 'Croisières vers',
      highlights: {
        reasonsTo: 'Raisons de'
      },
      seasonality: {
        title: 'Quand partir',
        bestTime: 'Meilleure période',
        goodTime: 'Bonne période',
        noServiceTo: 'Aucun service à',
        from: 'depuis',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Navires des',
      shipsToShortDescription: 'Explorez nos navires sur la destination',
      topExperiencesTitle: 'Meilleures expériences',
      seeAllFaqs: 'Voir toutes les FAQ',
      secondaryTitle: 'Nos destinations',
      activities: {
        footnote: '* Veuillez noter qu’il s’agit d’activités optionnelles moyennant un supplément.',
        seeAllActivities: 'Voir toutes les activités pour {name}',
        title: 'Activités de {name}'
      },
      map: {
        button: 'Lisez notre guide de voyage et nos FAQ.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Consulter les croisières'
        }
      }
    }
  },
  'da-DK': {
    destination: {
      title: 'Udforsk verden sammen med os',
      description: 'Læs mere om de spektakulære destinationer og attraktioner, du kan opleve på et ekspeditionskrydstogt med os.',
      destinationsButton: 'Gå til destination',
      storiesTitle: 'Historier fra',
      associatedVoyagesTitle: 'Krydstogter til',
      highlights: {
        reasonsTo: 'Årsager til'
      },
      seasonality: {
        title: 'Hvad kan du opleve, og hvornår',
        bestTime: 'Bedste tidspunkt',
        goodTime: 'Godt tidspunkt',
        noServiceTo: 'Ingen service til',
        from: 'fra',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Skibe til',
      shipsToShortDescription: 'Udforsk vores skibe, som regelmæssigt sejler til',
      topExperiencesTitle: 'Uovertrufne oplevelser',
      seeAllFaqs: 'Se alle ofte stillede spørgsmål',
      secondaryTitle: 'Vores destinationer',
      activities: {
        footnote: '* De valgfrie aktiviteter skal der muligvis betales ekstra for.',
        seeAllActivities: 'Se alle aktiviteter for {name}',
        title: 'Aktiviteter i {name}'
      },
      map: {
        button: 'Læs vores rejseguide og ofte stillede spørgsmål.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Vis krydstogter'
        }
      }
    }
  },
  'sv-SE': {
    destination: {
      title: 'Upptäck världen med oss',
      description: 'Läs mer om de oförglömliga resmål och sevärdheter som du kan uppleva på en expeditionskryssning med oss',
      destinationsButton: 'Gå till resmål',
      storiesTitle: 'Berättelser från',
      associatedVoyagesTitle: 'Kryssningar till',
      highlights: {
        reasonsTo: 'Anledningar att'
      },
      seasonality: {
        title: 'Vad du kan uppleva och när',
        bestTime: 'Bästa tidpunkt',
        goodTime: 'Bra tid',
        noServiceTo: 'Inga resor till',
        from: 'från',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Fartyg till',
      shipsToShortDescription: 'Utforska de fartyg som används under våra',
      topExperiencesTitle: 'Populära upplevelser',
      seeAllFaqs: 'Visa alla vanliga frågor',
      secondaryTitle: 'Våra destinationer',
      activities: {
        footnote: '*Observera att detta är tillvalsaktiviteter, så ytterligare kostnader kan tillkomma.',
        seeAllActivities: 'Se alla aktiviteter i {name}',
        title: 'Aktiviteter i {name}'
      },
      map: {
        button: 'Läs vår reseguide och vanliga frågor.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Visa kryssningar'
        }
      }
    }
  },
  'nb-NO': {
    destination: {
      title: 'Utforsk verden med oss',
      description: 'Lær mer om de spektakulære destinasjonene og høydepunktene du kan oppleveve på ekspedisjonscruise med oss. ',
      destinationsButton: 'Gå til destinasjonen',
      storiesTitle: 'Historier fra',
      associatedVoyagesTitle: 'Cruise til',
      highlights: {
        reasonsTo: 'Grunner til å'
      },
      seasonality: {
        title: 'Hva du kan oppleve, og når',
        bestTime: 'Beste tidspunkt',
        goodTime: 'Godt tidspunkt',
        noServiceTo: 'Ingen tilbud til',
        from: 'fra',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Skip til',
      shipsToShortDescription: 'Utforsk skipene våre som ofte reiser til',
      topExperiencesTitle: 'Opplevelser du må få med deg',
      seeAllFaqs: 'Se alle ofte stilte spørsmål',
      secondaryTitle: 'Destinasjonene våre',
      activities: {
        footnote: '* Merk at dette er valgfrie aktiviteter som kan koste ekstra.',
        seeAllActivities: 'Se alle aktiviteter for {name}',
        title: 'Aktiviteter i {name}'
      },
      map: {
        button: 'Les vår reiseguide og ofte stilte spørsmål.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'Se cruise'
        }
      }
    }
  },
  'en': {
    destination: {
      title: 'Explore the world with us',
      description: 'Learn more about the spectacular destinations and attractions that you can experience on an expedition cruise with us',
      destinationsButton: 'Go to destination',
      storiesTitle: 'Stories from',
      associatedVoyagesTitle: 'Cruises to',
      highlights: {
        reasonsTo: 'Reasons to'
      },
      seasonality: {
        title: 'When to see',
        bestTime: 'Best time',
        goodTime: 'Good time',
        noServiceTo: 'No service to',
        from: 'from',
        buttonText: 'Search for best {title} cruises'
      },
      shipsTo: 'Ships to',
      shipsToShortDescription: 'Explore our ships that regularly sail to',
      topExperiencesTitle: 'Top experiences',
      seeAllFaqs: 'See all FAQs',
      secondaryTitle: 'Our destinations',
      activities: {
        footnote: '* Please note that these are optional activities; an additional cost may apply.',
        seeAllActivities: 'See all activities for {name}',
        title: 'Activities in {name}'
      },
      map: {
        button: 'Read our travel guide and FAQs.'
      },
      hero: {
        anchorLink: {
          viewCruises: 'View cruises'
        }
      }
    }
  }
}

export type TDestination = {
  destination: {
    title: string;
    description: string;
    destinationsButton: string;
    storiesTitle: string;
    associatedVoyagesTitle: string;
    highlights: {
      reasonsTo: string;
    };
    seasonality: {
      title: string;
      bestTime: string;
      goodTime: string;
      noServiceTo: string;
      from: string;
      buttonText: string;
    };
    shipsTo: string;
    shipsToShortDescription: string;
    topExperiencesTitle: string;
    seeAllFaqs: string;
    secondaryTitle: string;
    activities: {
      footnote: string;
      seeAllActivities: string;
      title: string;
    };
    map: {
      button: string;
    };
    hero: {
      anchorLink: {
        viewCruises: string;
      };
    };
  };
}
